
// 全局过滤器

export function filter(Vue){

   // 1>. 获取到期天数
   Vue.filter('getExpirationTime', function (value) {

      if(value == null || value == undefined){
         return '免费';
      }
      
      var end_time = new Date(value).getTime(); // 毫秒数
      var now_time = new Date().getTime();

      var resetSec = end_time - now_time;

      var day = parseInt(resetSec/(60*60*24*1000));
      var hour = parseInt(resetSec/(60*60*1000)%24);
      var minu = parseInt(resetSec/(60*1000)%60);
      var sec = parseInt(resetSec/1000%60);

      return day + '天' + hour + '小时';
   })


   // 2>. 时间戳(秒)转日期时间字符串
   Vue.filter('timestampFormatToDateTime', function (value) {

      var d = new Date(value * 1000);    //根据时间戳生成的时间对象
      return (d.getFullYear()) + "-" + 
           (d.getMonth() + 1) + "-" +
           (d.getDate()) + " " + 
           (d.getHours()) + ":" + 
           (d.getMinutes()) + ":" + 
           (d.getSeconds());
   })

   // 2.1>. 时间戳(秒)转时间字符串
   Vue.filter('timestampFormatToTime', function (value) {

      var d = new Date(value * 1000);    //根据时间戳生成的时间对象
      return (d.getHours()) + ":" + 
           (d.getMinutes()) + ":" + 
           (d.getSeconds());
   })

   // 3>. 数字加单位
   Vue.filter('numberAddFinancialUnit', function (value) {

      if(value > 1000000000000){   // 万亿
         return (value/1000000000000).toFixed(2) + "万亿"
      }else if(value > 100000000){ // 亿
         return (value/100000000).toFixed(2) + "亿"
      }else if(value > 10000){     // 万
         return (value/10000).toFixed(2) + "万"
      }else{
         return value
      }
   })


   // 4>. 获取日期时间至现在的时间长度，参数为秒级时间戳
   Vue.filter('getTimeDuration', function (timestamp) { 
   
      timestamp = timestamp * 1000;   // 转为毫秒
      let now_time  = new Date().getTime(); // 毫秒数

      var resetSec = now_time - timestamp;

      var day = parseInt(resetSec/(60*60*24*1000));
      var hour = parseInt(resetSec/(60*60*1000)%24);
      var minu = parseInt(resetSec/(60*1000)%60);
      var sec = parseInt(resetSec/1000%60);

      return day; 
   })
   

}

