<template>
    <div class="content">
        <span>{{currentTime}}</span>
        <a @click="logout()"><i class="iconfont icon-084tuichu" style="margin-right:2px"></i>退出</a>
    </div>
</template>

<script>
export default {
    data(){
        return {
            currentTime:"",
            timer: null  // 定时器
        }
    },
    mounted(){
        this.timer = setInterval(() => {
            this.currentTime = this.$common.timestampToTime(Date.now()/1000);
        }, 1000);
    },
    methods:{
        logout:function(){
            localStorage.removeItem('TOKEN');
            this.$router.replace("login");
        },
    }
}
</script>

<style scoped>

.content{
    position:absolute;
    height: var(--top-navigation-height);
    top:0px;
    left: var(--sidebar-menu-width);
    width: calc(100% - var(--sidebar-menu-width));
    border-bottom: 1px solid #dcdcdc;
    line-height: var(--top-navigation-height);
    padding: 0px 14px;
    box-sizing: border-box;
    font-size: 15px;
}

.content a{
    float: right;
    cursor: pointer;
}


</style>