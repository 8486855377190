<template>
    <div>
        <TopNavigation></TopNavigation>
        <SidebarMenu></SidebarMenu>
        
        <!-- 默认视图,router-view没有设置名字，则默认名称为 default -->
        <router-view class="main"></router-view>

    </div>
</template>

<script>

import TopNavigation from "@/views/home/TopNavigation";
import SidebarMenu from "@/views/home/SidebarMenu";

export default {
    components:{
        SidebarMenu,
        TopNavigation,
    },
    mounted(){
        
    }
    
}
</script>

<style scoped>

.main{
    position:absolute;
    top: var(--top-navigation-height);
    left: var(--sidebar-menu-width);
    width: calc(100% - var(--sidebar-menu-width));
}

</style>


