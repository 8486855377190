import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Index.vue'
import Overview from "@/views/home/Overview";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Login.vue')
  },
  {
    path: '/',
    component: Home,
    children:[ 
      {
          path:'/',
          name:"overview",
          component:Overview
      },
      {
        path:'/user',
        name:"user",
        component: () => import('@/views/user/Index.vue')
      },
      {
        path:'/task',       // 定时任务
        name:"task",
        component: () => import('@/views/task/Index.vue')
      },
      {
        path:'/infoReport',  // 信息上报
        name:"infoReport",
        component: () => import('@/views/infoReport/Index.vue')
      },
      {
        path:'/report',
        name:"report",
        component: () => import('@/views/report/Index.vue')
      },
      {
        path:'/frp-user',
        name:"frp-user",
        component: () => import('@/views/frp/User.vue')
      },
      {
        path:'/frp-protocol',
        name:"frp-protocol",
        component: () => import('@/views/frp/Protocol.vue')
      },
      {
        path:'/exception',
        name:"exception",
        component: () => import('@/views/exception/Index.vue')
      },
      {
        path:'/logView',
        name:"logView",
        component: () => import('@/views/logView/Index.vue')
      },
      {
        path:'/messageQueue',
        name:"messageQueue",
        component: () => import('@/views/messageQueue/Index.vue')
      },
      {
        path:'/stock',
        name:"stock",
        component: () => import('@/views/stock/Index.vue')
      },
      {
        path:'/orderList',
        name:"orderList",
        component: () => import('@/views/stock/OrderList.vue')
      },
      {
        path:'/realtimeDataQuote',
        name:"realtimeDataQuote",
        component: () => import('@/views/stock/RealtimeDataQuote.vue')
      },
      {
        path:'/realtimeDataKline',
        name:"realtimeDataKline",
        component: () => import('@/views/stock/RealtimeDataKline.vue')
      },
      {
        path:'/realtimeDataTicker',
        name:"realtimeDataTicker",
        component: () => import('@/views/stock/RealtimeDataTicker.vue')
      },
      {
        path:'/realtimeData',
        name:"realtimeData",
        component: () => import('@/views/stock/RealtimeData.vue')
      },
      {
        path:'/stock-log',
        name:"stock-log",
        component: () => import('@/views/stock/Log.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// 注册全局导航守卫
// 导航守卫可监听路由跳转的整个过程；导航守卫有多种，如：全局的、单个路由独享的、组件级的；详见：https://router.vuejs.org/zh/guide/advanced/navigation-guards.html#%E5%85%A8%E5%B1%80%E5%89%8D%E7%BD%AE%E5%AE%88%E5%8D%AB
router.beforeEach((to, from, next) => {
    // console.log(to);
    // console.log(from);

    // 如果token不存在，且即将跳转的页面不是登录页面时，则跳转到登录页面
    var noRequiredLoginPage = ["login","register","password"];
    if(localStorage.getItem("TOKEN") == null){
        // token失效时，只能跳转至登录、注册等页面
        if(noRequiredLoginPage.indexOf(to.name)  != -1){
            next();
        }else{
            next({ name: 'login'});   // 否则，强制跳转到登录      
        }
    }else{
        // token有效时，不能跳转至登录、注册等页面 
        if(noRequiredLoginPage.indexOf(to.name)  != -1){
            next('/'); 
        }else{
            // 否则放行,继续下一步
            next();  
        }
    }
});


// 捕获路由异常
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


export default router
