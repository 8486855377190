

// uuid库，https://github.com/uuidjs/uuid
import { v4 as uuidv4 } from 'uuid';


// 全局变量 <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<


// 1.0> 生成本地唯一的UUID（UUID本身有重复的机率，此函数可避免重复）
const uuid = function(){

   // 获取微秒级时间戳
   let timestamp =  window.performance.timing.navigationStart + window.performance.now();
   let uuid = uuidv4() + "-" + timestamp;
   return uuid;
}

// 2.0> 获取url中的参数；使用示例：getUrlParam('paramName');
const getUrlParam = function(name) {
   var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
   var r = window.location.search.substr(1).match(reg);
   if (r != null) return unescape(r[2]); return null;
}

// 3.0> 时间戳(秒)转字符串
const timestampToTime = function(timestamp) {
   var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
   var Y = date.getFullYear() + '-';
   var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
   var D = date.getDate() + ' ';
   var h = date.getHours() + ':';
   var m = date.getMinutes() + ':';
   var s = date.getSeconds();
   return Y + M + D + h + m + s;
}


// 4>. 获取日期时间至现在的时间长度，参数为秒级时间戳
const getTimeDuration = function(timestamp){

   timestamp = timestamp * 1000;   // 转为毫秒
   let now_time  = new Date().getTime(); // 毫秒数
   var resetSec = now_time - timestamp;

   let result = "";
   var day = parseInt(resetSec/(60*60*24*1000));
   if(resetSec>(60*60*24*1000) && day > 0){  // 如果resetSec过小，算出的day会是一个指数，而指数无法直接进行比较，因此此处要限制resetSec的大小
      result += (day + "天")
   }else{
      day = 0
   }
   var hour = parseInt(resetSec/(60*60*1000)%24);
   if(hour > 0){
      result += (hour + "小时")
   }

   // 结果超过1天时，不显示分、秒
   if(day > 0){
      return result
   }

   var minu = parseInt(resetSec/(60*1000)%60);
   if(minu > 0){
      result += (minu + "分")
   }

   // 结果超过1小时时，不显示秒
   if(hour > 0){
      return result
   }

   var sec = parseInt(resetSec/1000%60);
   if(sec > 0){
      result += (sec + "秒")
   }

   return result===""?"0秒":result; 
}

export default {

    // 全局变量 ----------------------------------------------------------------------------------------------------

    // 全局方法 ----------------------------------------------------------------------------------------------------
    uuid,
    getUrlParam,
    getTimeDuration,
    timestampToTime
}