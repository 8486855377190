import axios from 'axios'; // axios官网：https://github.com/axios/axios
// import QS from 'qs'        // 引入qs模块，用来序列化post类型的数据
import router from '../router'
import {Message} from 'element-ui';

// 全局静态请求信息设置
// X-Requested-With用于标示请求是ajax，没此字段时所有请求都会被服务器视为浏览器直接访问
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

// 设置基地址
if(process.env.VUE_APP_ENV_NAME === "ONLINE"){ // 线上
   axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
}else{ // 线下
   axios.defaults.baseURL = '/base-test';     
}

// 请求拦截器
axios.interceptors.request.use(
    config => {
        // 请求发送之前

        // 动态设置公共头信息
        let token = localStorage.getItem("TOKEN");
        if(token){
            config.headers.common["authorization"] = token;
        }
        return config
    },
    err => {
        Message.error({center:true,message:err.message});
        return Promise.reject(err)
    }
);

// 响应拦截器
axios.interceptors.response.use(
    response => {
        // 任何位于2xx范围内的状态码都会触发此功能
        // 处理响应数据

        // 鉴权失败，跳转至登录页面
        if(response.data.code == 403){
            localStorage.removeItem('TOKEN');
            router.replace("/login");
            return;
        }

        // 消息提示
        if(response.data.status_code != 0){
            Message.error({center:true,message:response.data.message});
        }

        // 对响应数据做些事
        if(!response.data.success){
            return Promise.resolve(response);
        }
        return response
    }, error => {
        // 网络错误、DNS无法解析等错误时，error.response为null
        if(error.response && error.response.status == 403){ // 接口返回403，表明无权限，直接跳转至登录页面
            localStorage.removeItem('TOKEN');
            router.replace("/login");
        }else{
            // 任何超出2xx范围的状态码都会触发此函数 
            Message.error({message:error.message,center: true});
            return Promise.reject(error) // 返回接口返回的错误信息
        }
    }
);


// 封装axios的get方法和post方法
// Promise详解：https://www.liaoxuefeng.com/wiki/1022910821149312/1023024413276544
export function get(url, params){
    return new Promise((resolve, reject) => {
        axios.get(url, { params: params }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.data)
        })
    })
}

export function post(url, params) {
    return new Promise((resolve, reject) => {
        // axios.post(url, QS.stringify(params))
        axios.post(url,params).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.data)
        })
    })
}

export function customRequest(config) {
    return new Promise((resolve, reject) => {
        axios(config).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}