<template>
    <div class="content">
        <div class="userInfo">
            <div>
                <img src="">
                <i class="avatar iconfont icon-yonghutouxiang"></i>
            </div>
            <div class="username">YANGWW</div>
        </div>
        <div class="menuItem" @click="clickMenuItem(item.name)" v-bind:class="{active: item.isActive}" v-for="(item,index) in menuList" :key="index">
            <i class="icon iconfont" v-bind:class="item.icon"></i> {{item.title}}
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            menuList:[
                {"name":"overview","title":"首页","pageName":"overview","isActive":true,"icon":"bold icon-shouye"},
                {"name":"user","title":"用户管理","pageName":"user","isActive":false,"icon":"bold icon-yonghuguanli"},
                {"name":"task","title":"定时任务","pageName":"task","isActive":false,"icon":"bold icon-yonghuguanli"},
                // {"name":"stock","title":"量化交易","pageName":"stock","isActive":false,"icon":"bold icon-shouye"},
                // {"name":"realtimeData","title":"量化交易 - 实时数据","pageName":"realtimeData","isActive":false,"icon":"bold icon-shouye"},
                // {"name":"realtimeDataKline","title":"量化交易 - 实时K线","pageName":"realtimeDataKline","isActive":false,"icon":"bold icon-shouye"},
                // {"name":"realtimeDataTicker","title":"量化交易 - 实时逐笔","pageName":"realtimeDataTicker","isActive":false,"icon":"bold icon-shouye"},
                // {"name":"realtimeDataQuote","title":"量化交易 - 实时报价","pageName":"realtimeDataQuote","isActive":false,"icon":"bold icon-shouye"},
                // {"name":"orderList","title":"量化交易 - 今日订单","pageName":"orderList","isActive":false,"icon":"bold icon-shouye"},
                // {"name":"stock-log","title":"量化交易 - 日志","pageName":"stock-log","isActive":false,"icon":"bold icon-shouye"},
                {"name":"infoReport","title":"信息上报","pageName":"infoReport","isActive":false,"icon":"bold icon-yonghuguanli"},
                {"name":"report","title":"举报信息","pageName":"report","isActive":false,"icon":"icon-jubao"},
                {"name":"frp-user","title":"Frp内网穿透 - 用户","pageName":"frp-user","isActive":false,"icon":"icon-houtai"},
                {"name":"frp-protocol","title":"Frp内网穿透 - 协议","pageName":"frp-protocol","isActive":false,"icon":"icon-houtai"},
                // {"name":"exception","title":"异常管理","pageName":"exception","isActive":false,"icon":"icon-yichangshijianliebiaocopy"},
                {"name":"logView","title":"日志管理","pageName":"logView","isActive":false,"icon":"icon-rizhi"},
                // {"name":"messageQueue","title":"消息队列","pageName":"messageQueue","isActive":false,"icon":"icon-loading-v"}
            ]
        };
    },
    mounted(){
        this.clickMenuItem(this.$route.name);
    },
    methods:{
        clickMenuItem:function(name){
            var count = this.menuList.length;
            var index = 0;
            for(var i=0;i<count;i++){
                if(this.menuList[i].name == name){
                    this.menuList[i].isActive = true;
                    index = i;
                }else{
                    this.menuList[i].isActive = false;
                }
            }
            this.$router.push({name: this.menuList[index].pageName});
        }
    }
}
</script>

<style scoped>

.content{
    position:absolute;
    top: 0px;
    width:var(--sidebar-menu-width);
    height: 100%;
    border-right: 1px solid #dcdcdc;
    z-index: 100;
    overflow-y: auto;
}

.userInfo{
    display:flex;
    padding: 10px;
    align-items:center;
    border-bottom: 1px solid #ecececf0;
}
.userInfo i.avatar{
    font-size: 40px;
    color: gray;
}
.userInfo .username{
    padding-left:10px;
    font-size: 18px;
    font-weight: bold;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
}

.menuItem{
    padding: 10px 10px;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 15px;
}
.menuItem:hover{
    background: #8080801f;
}
.menuItem.active{
    background: #407fea;
    color:white;
}
.menuItem>.icon{
    margin: 0px 5px;
}

</style>