<!-- 根组件 -->
<template>
  <div id="app">
    <router-view/>    
  </div>
</template>

<script>
export default {
  created(){
  
  }
}
</script>

<style>

:root{
    --sidebar-menu-width:210px;
    --top-navigation-height:37px;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
html,body,div{
  margin: 0px;
  padding: 0px;
}

input,button{
  font-size: 15px;
}


.container{
  height:calc(100% - var(--top-navigation-height));
  overflow:auto;
}

/* a标签 ------------------------------------------------------------------------------ */
a {
  color: #337ab7;
  text-decoration: none!important;
}

/* 阿里矢量图标通用样式 ----------------------------------------------------------------- */
.iconfont.bold::before{
  font-weight: bold;
}

/* 通用loading ----------------------------------------------------------------------- */
.commonLoading{
   display:flex;
   justify-content:center;
   align-items:center;
   width: 100%;
   height: 100%;
   font-size: 30px;
   color: gray;

   position: absolute;
   top: 0px;
   background: #ffffff9c;
}

/* 通用的无数据 ----------------------------------------------------------------------- */
.commonNotData{
  display:flex;
  justify-content:center;
  align-items:center;
  width: 100%;
  height: 100%;
  font-size: 30px;
  color: gray;
}

/* 通用搜索样式 ------------------------------------------------------------------------ */
.commonSearch{
    border-bottom: 1px solid #DCDFE6;
}
.commonSearch>input{
    border-radius: 0px;
    border: 0px;
}

/* 通用表格样式 ------------------------------------------------------------------------ */
.commonList>table{
  width: 100%;
  border-collapse:collapse;
}
.commonList>table td{
  padding: 8px 0px;
  border:1px solid #ececec;
  text-align: center;
}
.commonList>table>thead{
  color: #333333;
  font-weight: bold;
}


</style>
