import tool from 'web-tool-library';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';

// 0.0 引入自定义工具组件库 ----------------------------------------------------------------
Vue.use(tool);

// 0.1 饿了么UI ------------------------------------------------
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 2.0 创建全局$api对象 ------------------------------------------
import * as Api from './service/api.js'
Vue.prototype.$api = Api;

// 9.0 全局过滤器
import { filter } from './service/filter'
filter(Vue);

// 0.0 引入全局变量和方法
import common from './service/common.js';
Vue.prototype.$common = common;


Vue.prototype.$env = process.env.NODE_ENV;

Vue.config.productionTip = false

// 逻辑入口文件
new Vue({
  router,  // 为实例注入路由
  store,   // 为实例注入store
  render: h => h(App)
}).$mount('#app');   // $mount('#app')表示将vue实例挂载到#app的元素上

