<template>
    <div class="container">

        <!-- // ------------------------------------------------------------------------------------------------ -->
        <div class="overview">
            <div class="item">注册人数：{{data.userCount}}</div>
            <div class="item">内网穿透：{{data.frpUserCount}}</div>
            <div class="item">文档托管：{{data.docArticleUserCount}}</div>
            <div class="item">文章：{{data.articleUserCount}}</div>
        </div>

        <br/><br/>
        <div class="commonList">
            <!-- Sidoc主服务 ---------------------------------------------------------------------------- -->
            <table>
                <thead>
                    <tr>
                        <td>Sidoc主服<span class="subtitle">（阿里2C4G）</span></td>
                        <td colspan=2>线上<span class="subtitle">（前端/后端）</span></td>
                        <td colspan=2>开发<span class="subtitle">（前端/后端）</span></td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in mainServerData" :key="index">
                        <td>{{item.name}}</td>
                        <td><a :href="'https://' + item.web" target="_blank" >{{item.web}}</a></td>
                        <td><a :href="'https://' + item.server" target="_blank" >{{item.server}}</a></td>
                        <td><a :href="'https://' + item.webDev" target="_blank" >{{item.webDev}}</a></td>
                        <td><a :href="'https://' + item.serverDev" target="_blank" >{{item.serverDev}}</a></td>
                    </tr>
                </tbody>
            </table>


            <!-- Sidoc内网穿透服务 ---------------------------------------------------------------------------- -->
            <table v-for="(server,sIndex) in frpServer" :key="sIndex">
                <thead>
                    <tr>
                        <td>内网穿透</td>
                        <td>线上</td>
                        <td>开发</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in server" :key="index">
                        <td>{{item.name}}<span class="subtitle">{{item.subtitle}}</span></td>
                        <td><a :href="'https://' + item.server" target="_blank" >{{item.server}}</a></td>
                        <td><a :href="'https://' + item.serverDev" target="_blank" >{{item.serverDev}}</a></td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>

<script>
export default {
    data(){
        return {
            isLoading:false,
            data:{},
            mainServerData:[
                { name:"Sidoc",web:"",server:"sidoc.cn",webDev:"",serverDev:"sidoc.vip"},
                { name:"Sidoc后台管理",web:"admin.sidoc.cn",server:"service.admin.sidoc.cn",webDev:"admin.sidoc.vip",serverDev:"service.admin.sidoc.vip"},
                { name:"Sidoc用户中心",web:"",server:"service.user.sidoc.cn",webDev:"",serverDev:"service.user.sidoc.vip"},
                { name:"内网穿透总服",web:"frp.sidoc.cn",server:"service.frp.sidoc.cn",webDev:"frp.sidoc.vip",serverDev:"service.frp.sidoc.vip"},
                { name:"专有小店",web:"xiaodian.sidoc.cn",server:"service.xiaodian.sidoc.cn",webDev:"xiaodian.sidoc.vip",serverDev:"service.xiaodian.sidoc.vip"},
                { name:"地图服务",web:"",server:"",webDev:"",serverDev:""},
                { name:"公共静态服务",web:"statics.sidoc.cn",server:"",webDev:"statics.sidoc.vip",serverDev:""},
            ],
            frpServer:[
                [
                    { name:"C1",subtitle:"（腾迅2C4G）",web:"",server:"c1.sidoc.cn",webDev:"",serverDev:"cn.sidoc.vip"},
                ]
            ]

        }
    },
    mounted(){
        //   this.loadDataStatistics();
    },
    methods:{
        // 获取统计数据
        loadDataStatistics(){
            this.$api.dataStatisticsPost().then(res => {
                if(res.status_code == 0){
                this.data = res.data;
                }
            }).finally(()=>{
                
            });
        }
    }
        
}
</script>

<style scoped>

.container {
   padding: 20px;
   box-sizing: border-box;
   overflow: auto;
}
.overview{
   display:flex;
   flex-wrap: wrap;
}
.overview>.item{
   margin-right: 60px;
}

.commonList{
   display:flex;
   flex-wrap: wrap;
}
.commonList>*{
    margin-bottom: 60px;
}
.subtitle{
    font-weight: normal;
    color: gray;
}


</style>