import { get, post, customRequest } from './http'

// 自定义请求
export const request = cab => customRequest(cab); 

// 首页 - 获取统计数据
export const dataStatisticsPost = cab => post('statistics/dataStatistics', cab); 

// 获取用户上报的信息
export const infoReportList = cab => post('/infoReport', cab); 

// 任务管理
export const taskListPost = cab => post('task/taskList', cab); 

// 异常管理
export const exceptionListPost = cab => post('admin/exception_controller/cxceptionList', cab); 
// 异常管理 - 更新异常状态
export const updateProcessedStatusPost = cab => post('admin/exception_controller/updateProcessedStatus', cab); 


// 举报信息
export const reportListPost = cab => post('admin/report_controller/reportList', cab); 


// Frp内网穿透 - 服务器列表
export const frpServerList = cab => post('/frp/serverList', cab); 
// Frp内网穿透 - 用户列表
export const frpUserListPost = cab => post('/frp/userList', cab); 
// Frp内网穿透 - 协议列表
export const protocolListPost = cab => post('/frp/protocolList', cab); 


// 消息队列
export const messageQueueFindPost = cab => post('admin/message_queue/messageList', cab); 
// 消息队列 - 发送Frp消息
export const messageQueueSendFrpPost = cab => post('admin/message_queue/sendFrpMessage', cab); 
// 消息队列 - 发送测试消息
export const messageQueueSendTestPost = cab => post('admin/message_queue/sendTestMessage', cab); 
// 消息队列 - 清空消息
export const messageQueueClearPost = cab => post('admin/message_queue/clearMessage', cab); 

// 日志管理 - sidoc日志
export const loadLogListPost = cab => post('admin/log_manager/logList', cab); 
export const loadLogContentPost = cab => post('admin/log_manager/logContent', cab); 
